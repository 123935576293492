import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function LandScreen() {
  const navigate = useNavigate();

  const handleNavigateLogin = async () => {
    navigate("/login");
  };
  const handleNavigateRegister = async () => {
    navigate("/signup");
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <Box
      noValidate
      sx={{
        mt: 1,
        display: "flex",
        justifyContent: "end",
        backgroundColor: "aliceblue",
      }}
    >
      <Button
        variant="contained"
        onClick={handleNavigateLogin}
        sx={{ mt: 3, mb: 2, mr: 5 }}
      >
        Sign In
      </Button>
      <Button
        variant="contained"
        onClick={handleNavigateRegister}
        sx={{ mt: 3, mb: 2, mr: 10 }}
      >
        Sign Up
      </Button>
    </Box>
  );
}

export default LandScreen;
