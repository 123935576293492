import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

import { Formik } from "formik";
import * as Yup from "yup";
import { showSuccessToaster } from "../utils";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    marginTop: 0,
  },
}));

function TaxInfo() {
  const TaxData = localStorage.getItem("taxInfo");
  const Tax = JSON.parse(TaxData);
  const classes = useStyles();
  const handleOnSubmit = async (values) => {
    localStorage.setItem("taxInfo", JSON.stringify(values));
    showSuccessToaster("Tax info stored successfully");
  };

  const size = [
    "two_x_one_5",
    "three_x_two",
    "two_25_x_one_25",
    "four_x_two",
    "two_375_x_three",
    "two_x_two",
  ];

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Add Tax Info
        </Typography>
        <Formik
          initialValues={{
            sales_tax: Tax?.sales_tax || 0.102,
            liter_tax: Tax?.liter_tax || 0.0037708,
            sprit_tax: Tax?.sprit_tax || 0.205,
            size: Tax?.size || "two_x_one_5",
          }}
          validationSchema={Yup.object().shape({
            sales_tax: Yup.string().required("Sales Tax is required"),
            liter_tax: Yup.string().required("Liter Tax is required"),
            sprit_tax: Yup.string().required("Sprit Tax is required"),
            size: Yup.string().required("Size is required"),
          })}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                variant="standard"
                error={Boolean(touched.sales_tax && errors.sales_tax)}
                sx={{ mt: 0 }}
              >
                <TextField
                  id="sales_tax"
                  type="number"
                  value={values.sales_tax}
                  name="sales_tax"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  label="Sales Tax"
                />

                {touched.sales_tax && errors.sales_tax && (
                  <FormHelperText error id="sales_tax">
                    {errors.sales_tax}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="standard"
                error={Boolean(touched.liter_tax && errors.liter_tax)}
                sx={{ mt: 0 }}
              >
                <TextField
                  id="liter_tax"
                  type="number"
                  value={values.liter_tax}
                  name="liter_tax"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  label="Liter Tax"
                />

                {touched.liter_tax && errors.liter_tax && (
                  <FormHelperText error id="liter_tax">
                    {errors.liter_tax}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                variant="standard"
                error={Boolean(touched.sprit_tax && errors.sprit_tax)}
                sx={{ mt: 0 }}
              >
                <TextField
                  id="sprit_tax"
                  type="number"
                  value={values.sprit_tax}
                  name="sprit_tax"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  label="Sprit Tax"
                />

                {touched.sprit_tax && errors.sprit_tax && (
                  <FormHelperText error id="sprit_tax">
                    {errors.sprit_tax}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel id="demo-simple-select-label">Size</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={values?.size}
                  classes={{ root: classes.inputStyle }}
                  label="Size"
                  name="size"
                  onChange={(e) => handleChange(e)}
                  onBlur={(e) => handleBlur(e)}
                >
                  {size.map((i) => (
                    <MenuItem value={i} key={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{touched?.size && errors?.size}</FormHelperText>
              </FormControl>

              <Box sx={{ mt: 2 }} className="text-center">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );
}

export default TaxInfo;
