import React from "react";
import { Amplify } from 'aws-amplify';
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CssBaseline, StyledEngineProvider } from "@mui/material";
import NavBar from "./utils/Navbar";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import UserList from "./screens/UserList";
import ForgotPassWord from "./screens/ForgotPassword";
import AllProduct from "./screens/AllProduct";
// import GetProduct from "./screens/GetProduct";
import UpdatedProduct from "./screens/UpdatedProduct";
import ResetPassword from "./screens/ResetPassword";
import LandScreen from "./screens/LandScreen";
import AddProduct from "./screens/AddProduct";
import TaxInfo from "./screens/TaxInfo";

let Auth = {
  region: "us-east-1",
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  responseType: "token",
};

Amplify.configure({ Auth });

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ToastContainer autoClose={5000} pauseOnFocusLoss={false} />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<LandScreen />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/userlist" element={<UserList />} />
        <Route exact path="/forgotpassword" element={<ForgotPassWord />} />
        <Route exact path="/resetpassword" element={<ResetPassword />} />
        <Route exact path="/addproduct" element={<AddProduct />} />
        <Route exact path="/allproducts" element={<AllProduct />} />
        <Route exact path="/taxinfo" element={<TaxInfo />} />
        {/* <Route exact path="/getproduct" element={<GetProduct />} /> */}
        <Route exact path="/updatedproduct" element={<UpdatedProduct />} />
      </Routes>
    </StyledEngineProvider>
  );
}

export default App;
