import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Button,
  CardContent,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { showErrorToaster, showSuccessToaster } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

function CreateUser({ setOpenModal, getUserList }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    const companyName = localStorage.getItem("company-name");

    try {
      const headers = {
        "Content-Type": "application/json",
        user: "admin",
      };
      values.group = "store_employee";
      values.preferred_username = values.email;
      values.company = companyName;
      values.phone_number = `+1${values.phone_number.toString()}`;
      const createEmployeeUser = await axios.post(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/createuser",
        values,
        {
          headers: headers,
        }
      );
      if (createEmployeeUser?.data?.User?.Username) {
        showSuccessToaster("User Created Successfully");
        getUserList();
        navigate("/userlist");
        setIsLoading(false);
        setOpenModal(false);
      } else if (createEmployeeUser?.data?.errorMessage) {
        showErrorToaster("", createEmployeeUser?.data?.errorMessage);
        setIsLoading(false);
      }
    } catch (error) {
      showErrorToaster("", "Something Went Wrong.Please try again.");
      setIsLoading(false);
      setOpenModal(false);
    }
  };
  return (
    <CardContent
      sx={{ p: 2, height: "100%", maxHeight: "500px", overflowY: "auto" }}
    >
      <Formik
        initialValues={{
          given_name: "",
          family_name: "",
          email: "",
          phone_number: "",
          address: "",
          // preferred_username: "",
        }}
        validationSchema={Yup.object().shape({
          given_name: Yup.string().required("First Name is required"),
          family_name: Yup.string().required("Last Name is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          phone_number: Yup.string().required("Phone Number is required"),
          address: Yup.string().required("Address is required"),
          // preferred_username: Yup.string().required("User Name is required"),
        })}
        onSubmit={handleOnSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.given_name && errors.given_name)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="given_name"
                type="text"
                value={values.given_name}
                name="given_name"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="First Name"
              />

              {touched.given_name && errors.given_name && (
                <FormHelperText error id="given_name">
                  {errors.given_name}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.middle_name && errors.middle_name)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="middle_name"
                type="text"
                value={values.middle_name}
                name="middle_name"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="Middle Name"
              />
            </FormControl>
            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.family_name && errors.family_name)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="family_name"
                type="text"
                value={values.family_name}
                name="family_name"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="Last Name"
              />

              {touched.family_name && errors.family_name && (
                <FormHelperText error id="family_name">
                  {errors.family_name}
                </FormHelperText>
              )}
            </FormControl>
            {/* <FormControl
                fullWidth
                variant="standard"
                error={Boolean(
                  touched.preferred_username && errors.preferred_username
                )}
                sx={{ mt: 0 }}
              >
                <TextField
                  id="preferred_username"
                  type="text"
                  value={values.preferred_username}
                  name="preferred_username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                  fullWidth
                  label="Username"
                />

                {touched.preferred_username && errors.preferred_username && (
                  <FormHelperText error id="preferred_username">
                    {errors.preferred_username}
                  </FormHelperText>
                )}
              </FormControl> */}
            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.email && errors.email)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="email"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="Email"
              />

              {touched.email && errors.email && (
                <FormHelperText error id="email">
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.address && errors.address)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="address"
                type="text"
                value={values.address}
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="Address"
              />

              {touched.address && errors.address && (
                <FormHelperText error id="address">
                  {errors.address}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl
              fullWidth
              variant="standard"
              error={Boolean(touched.phone_number && errors.phone_number)}
              sx={{ mt: 0 }}
            >
              <TextField
                id="phone_number"
                type="number"
                value={values.phone_number.toString()}
                name="phone_number"
                onBlur={handleBlur}
                onChange={handleChange}
                margin="normal"
                fullWidth
                label="Phone Number"
              />

              {touched.phone_number && errors.phone_number && (
                <FormHelperText error id="phone_number">
                  {errors.phone_number}
                </FormHelperText>
              )}
            </FormControl>

            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="text-center"
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size="25px" />
                ) : (
                  " Create User"
                )}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={() => {
                  setOpenModal(false);
                }}
                sx={{ mt: 3, mb: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </CardContent>
  );
}

export default CreateUser;
