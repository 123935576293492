import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slide,
  IconButton,
  Typography,
  Divider,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";

// project imports
import KeyboardDoubleArrowRightOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowRightOutlined";

import { showSuccessToaster, showErrorToaster } from "../utils";

// animation
const Transition = forwardRef((props, ref) => (
  <Slide direction="center" ref={ref} {...props} />
));

// ==============================|| PRODUCT ADD DIALOG ||============================== //

const DataUploadDialog = ({
  open,
  handleCloseDialog,
  setIsLoading,
  excelData,
  setExcelData,
  file,
  setFile,
  columnsData,
  setColumnsData,
  isLoading,
  updatedProduct,
  setRows,
}) => {
  const [options, setOptions] = useState([]);

  const columns = React.useMemo(() => [
    {
      accessor: "sortKey",
      Header: "Postal Code",
    },
    {
      accessor: "sku",
      Header: "SKU",
    },
    {
      accessor: "name",
      Header: "Name",
    },
    {
      accessor: "code",
      Header: "Code",
    },
    {
      accessor: "commodity",
      Header: "Commodity",
    },
    {
      accessor: "price",
      Header: "Price",
    },
  ]);

  useEffect(() => {
    if (columnsData.length > 0) {
      const tempOptions = [];
      options.map((option) => {
        let tempFlage = false;
        columnsData.map((select) => {
          if (select.defaultValue === option.value) {
            tempFlage = true;
            tempOptions.push({
              value: option.value,
              name: option.name,
              isSelected: true,
            });
          }
        });
        if (!tempFlage) {
          tempOptions.push({
            value: option.value,
            name: option.name,
            isSelected: false,
          });
        }
      });
      setOptions(tempOptions);
    }
  }, [columnsData]);

  useEffect(() => {
    const tempOptions = [];
    columns.map((val, i) => {
      if (i > 0) {
        tempOptions.push({
          value: val.accessor,
          name: val.Header,
        });
      }
    });
    setOptions(tempOptions);
  }, []);

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const handelSelect = (e) => {
    setColumnsData(
      columnsData.map((val) => {
        if (val.csvColumnsName === e.target.name) {
          return {
            csvColumnsName: val.csvColumnsName,
            defaultValue: e.target.value,
          };
        }
        return val;
      })
    );
  };

  const addProductData = async (chunkData) => {
    const url = updatedProduct
      ? "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/getUpdatedProducts"
      : "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/storeProducts";
    const token = localStorage.getItem("accessToken");
    const headers = {
      Authorization: token,
    };
    const addProducts = await axios.post(url, chunkData, {
      headers: headers,
    });
    return addProducts;
  };

  const handleOnSubmit = async (e) => {
    try {
      let addProducts;
      setIsLoading(true);
      e.preventDefault();
      if (excelData.length) {
        const bulkDataArr = excelData.map((row, i) => {
          const temp = {};
          // if condition satisfy then add default value with csv file value
          columnsData.map((val) => {
            if (val.defaultValue && row[val.csvColumnsName]) {
              temp[val.defaultValue] = row[val.csvColumnsName].toString();
            }
          });
          return temp;
        });
        // split bulk_data for prevent lambda timeout issue
        const chunkSize = 50;
        for (let i = 0; i < bulkDataArr.length; i += chunkSize) {
          const chunkData = bulkDataArr.slice(i, i + chunkSize);
          // do whatever
          addProducts = await addProductData(chunkData);
        }
        setIsLoading(true);

        if (
          addProducts?.data?.statusCode === 200 ||
          addProducts?.status === 200
        ) {
          if (updatedProduct) {
            setRows(addProducts?.data);
          } else {
            showSuccessToaster(addProducts?.data?.body);
          }
          setIsLoading(false);
        }
      }
      if (file) {
        setExcelData([]);
        setFile();
        handleCloseDialog();
      }
    } catch (error) {
      setIsLoading(false);
      showErrorToaster("Something went wrong. Please try after some time");
    }
  };

  const handleOnClose = () => {
    setExcelData([]);
    setFile();
    handleCloseDialog();
  };

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DataUploadComponent = () => {
    return (
      <>
        {columnsData.length ? (
          columnsData.map((col, i) => {
            return (
              <Grid container display={"flex"} sx={{ mt: 0.25 }} key={i}>
                <Grid item flex={4}>
                  <TextField
                    fullWidth
                    id="filled-start-adornment1"
                    variant="standard"
                    defaultValue={col.csvColumnsName}
                    inputProps={{ readOnly: true }}
                  >
                    {col.csvColumnsName}
                  </TextField>
                </Grid>
                <Grid
                  item
                  flex={1}
                  sx={{ justifyContent: "center", display: "flex" }}
                >
                  <KeyboardDoubleArrowRightOutlinedIcon />
                </Grid>
                <Grid item flex={4}>
                  <Select
                    fullWidth
                    variant="standard"
                    defaultValue={col.defaultValue}
                    onChange={handelSelect}
                    name={col.csvColumnsName}
                  >
                    {options.map((val, index) => {
                      return (
                        <MenuItem
                          value={val.value}
                          key={index}
                          disabled={val.isSelected}
                        >
                          {val.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid container sx={{ mt: 0.25 }} width="100%">
            <Grid item sx={{ width: "100%" }}>
              <h2 style={{ textAlign: "center" }}>
                Get started by uploading .csv
              </h2>
              <h4 style={{ textAlign: "center" }}>
                Enum nisi sit sed lobortis. Urna
                <br />
                massa suspendisse es in vitae ut.
              </h4>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      sx={{
        "&>div:nth-of-type(3)": {
          justifyContent: "center",
          "&>div": {
            m: 0,
            borderRadius: "10px",
            maxWidth: 800,
            maxHeight: "100%",
          },
        },
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleOnClose}>
        <b style={{ fontSize: "25px" }}>Data Upload</b>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DataUploadComponent />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            width: "150px",
            color: "black",
            borderRadius: "8px",
            borderStyle: "solid",
            borderColor: "gray",
            borderWidth: "2px",
          }}
          variant="text"
          onClick={handleOnClose}
        >
          cancel
        </Button>
        <Button
          disabled={!file || isLoading}
          sx={{ width: "150px", borderRadius: "8px", marginLeft: "15px" }}
          color="secondary"
          variant="contained"
          type="submit"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          {isLoading ? <CircularProgress size={18} /> : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DataUploadDialog.propTypes = {
  open: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
};

export default DataUploadDialog;
