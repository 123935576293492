import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  Modal,
  TableSortLabel,
  FormControl,
  FormHelperText,
  Checkbox,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
  CardContent,
  InputLabel,
  Typography,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import Loader from "../utils/Loader";
import DataUploadDialog from "../utils/DataUploadDialog";
import * as XLSX from "xlsx";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";

import { Formik } from "formik";
import * as Yup from "yup";
import { showErrorToaster, showSuccessToaster } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import MainCard from "../utils/MainCard";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  LiquorStyle: {
    backgroundColor: "yellow",
  },
  BeerStyle: {
    backgroundColor: "#f28e1c",
  },
  DrinksStyle: {
    backgroundColor: "#C19730",
  },
  FoodStyle: {
    backgroundColor: "#33FF99",
  },
  CandyStyle: {
    backgroundColor: "#33CEFF ",
  },
  TobaccoStyle: {
    backgroundColor: "#B9BFC1",
  },
  WineStyle: {
    backgroundColor: "#77B2E9",
  },
  row: {
    backgroundColor: "transparent",
  },
}));

const columns = [
  {
    id: "checkBox",
    label: "Select",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "code",
    label: "Code",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "commodity",
    label: "Commodity",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "sku",
    label: "SKU",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "price",
    label: "Price",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "salestax",
    label: "Sales Tax",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "waLitterTax",
    label: "waLitterTax",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "total",
    label: "Total",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

let selectedProduct = [];

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

export default function AddProduct() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customeropen, setCustomerOpen] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [file, setFile] = useState();
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectModifyProduct, setSelectModifyProduct] = React.useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const taxInfo = localStorage.getItem("taxInfo");
  const tax = JSON.parse(taxInfo);
  const classes = useStyles();

  // submit
  const [excelData, setExcelData] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseDialogCustomer = () => {
    setCustomerOpen(false);
  };

  const commodityData = [
    "Beer",
    "Drinks",
    "Food",
    "Liquor",
    "Candy",
    "Tobacco",
    "Wine",
  ];

  const openLink = (url) => {
    window.open(url, "_blank")?.focus();
    setSelectedProducts([]);
  };

  const handleOnSubmit = async (values) => {
    try {
      setIsLoading(true);
      values.price = values?.price.toString();
      const url =
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/storeProducts";
      const data = [{ ...selectModifyProduct, ...values }];
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
      };
      const addProducts = await axios.post(url, data, {
        headers: headers,
      });
      if (addProducts?.data?.statusCode === 200) {
        showSuccessToaster("Product updated successfully");
        setIsLoading(false);
        setOpenModal(false);
      }
    } catch (error) {
      showErrorToaster("", "Something Went Wrong.Please try again.");
      setIsLoading(false);
      setOpenModal(false);
    }
  };

  const handleCreatePDF = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("accessToken");

      const headers = {
        Authorization: token,
        sale: true,
        size: tax?.size || "two_x_one_5",
      };
      const createPdf = await axios.post(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/createPdf",
        selectedProducts,
        {
          headers: headers,
        }
      );
      if (createPdf?.data?.url) {
        setIsLoading(false);
        selectedProduct = [];
        setSelectedProducts([]);
        openLink(createPdf?.data?.url);
      }
    } catch (error) {
      showErrorToaster("", "Something Went Wrong.Please try again.");
      selectedProduct = [];
      setSelectedProducts([]);
      setIsLoading(false);
    }
  };

  const handleSelectedRow = (e, row) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, row]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== row));
    }
  };

  // handle File
  const fileType = ["application/vnd.ms-excel"];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    setSelectedFileName(selectedFile?.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      showErrorToaster("", "plz select your file");
    }
  };

  // submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedFileName("");
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      const filHeader = Object.keys(data[0]);
      const fileHeaderObject = filHeader.map((val) => {
        return {
          csvColumnsName: val,
          defaultValue: "",
        };
      });
      setColumnsData(fileHeaderObject);
      setExcelData(data);
      setCustomerOpen(true);
      setFile(excelFile);
    } else {
      setExcelData(null);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEditProduct = (row) => {
    setOpenModal(true);
    setSelectModifyProduct(row);
  };

  // table sort
  function descendingComparator(a, b, orderBy) {
    if (
      orderBy === "price" ||
      orderBy === "salestax" ||
      orderBy === "waLitterTax" ||
      orderBy === "total"
    ) {
      if (Number(b[orderBy]) < Number(a[orderBy])) {
        return -1;
      }
      if (Number(b[orderBy]) > Number(a[orderBy])) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  }

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = () => {
    setSelectedProducts(rows);
  };
  const handleDeSelectAllClick = () => {
    setSelectedProducts([]);
  };

  // ==============================|| TABLE HEADER ||============================== //
  function EnhancedTableHead({ order, orderBy, onRequestSort, columns }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ fontWeight: 600 }}
                style={headCell?.cellSize}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            sortDirection={false}
            align="center"
            sx={{ pr: 3, minWidth: 170 }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "grey.900",
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: "95%", margin: "auto", backgroundColor: "aliceblue" }}>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid sx={{ marginLeft: "10px", marginTop: "30px" }}>
          <Button
            variant="contained"
            sx={{ mr: 5 }}
            onClick={handleSelectAllClick}
          >
            Select All
          </Button>
          <Button
            variant="contained"
            sx={{ mr: 5 }}
            disabled={selectedProducts?.length === 0 ? true : false}
            onClick={handleDeSelectAllClick}
          >
            DeSelect All
          </Button>
          <Button
            variant="contained"
            onClick={handleCreatePDF}
            disabled={selectedProducts?.length === 0 ? true : false}
          >
            Get PDF Label
          </Button>
        </Grid>
        <Grid>
          <Typography sx={{ textAlign: "end", mr: 5 }}>
            Upload New Excel sheet
          </Typography>
          <Grid
            sx={{
              display: "flex",
              paddingTop: "10px",
              marginRight: "10px",
              marginBottom: "10px",
              justifyContent: "end",
            }}
          >
            <Grid
              xs={6}
              md={8}
              sx={{
                textAlign: "end",
              }}
            >
              <Button variant="outlined" component="label" sx={{ mr: 2 }}>
                <input
                  type="file"
                  hidden
                  className="form-control"
                  onChange={handleFile}
                  required
                ></input>
                Choose File
              </Button>
              {selectedFileName && <Typography>{selectedFileName}</Typography>}
              {excelFileError && (
                <div className="text-danger" style={{ marginTop: 5 + "px" }}>
                  {excelFileError}
                </div>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                component="label"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {file && (
        <DataUploadDialog
          open={customeropen}
          handleCloseDialog={handleCloseDialogCustomer}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          columnsData={columnsData}
          excelData={excelData}
          setExcelData={setExcelData}
          file={file}
          setFile={setFile}
          setColumnsData={setColumnsData}
          updatedProduct={true}
          setRows={setRows}
        />
      )}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            columns={columns}
          />
          <TableBody>
            {rows.length > 0 &&
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.code}>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Checkbox
                          checked={selectedProducts.some(
                            (a) => a.id === row.id
                          )}
                          onChange={(e) => handleSelectedRow(e, row)}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.name}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.code}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Typography
                          classes={{
                            root:
                              row?.commodity === "Beer"
                                ? classes.BeerStyle
                                : row?.commodity === "Drinks"
                                ? classes.DrinksStyle
                                : row?.commodity === "Food"
                                ? classes.FoodStyle
                                : row?.commodity === "Liquor"
                                ? classes.LiquorStyle
                                : row?.commodity === "Candy"
                                ? classes.CandyStyle
                                : row?.commodity === "Tobacco"
                                ? classes.TobaccoStyle
                                : row?.commodity === "Wine"
                                ? classes.WineStyle
                                : classes.row,
                          }}
                        >
                          {row?.commodity}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.sku}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.size}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.price}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.salesTax === "NaN" ? "" : row?.salesTax}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.waLitterTax === "NaN" ? "" : row?.waLitterTax}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.total === "NaN" ? "" : row?.total}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Tooltip title="Delete">
                          <IconButton
                            size="large"
                            onClick={() => handleEditProduct(row)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Grid item xs={6} sm={6}>
        <Modal
          open={openModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ mt: 10 }}
        >
          {/* {modelBody} */}
          <MainCard
            sx={{
              width: 450,
              zIndex: 1,
              margin: "auto",
            }}
            title="Modify Product"
            content={false}
          >
            <CardContent
              sx={{
                height: "100%",
                maxHeight: "600px",
                overflowY: "auto",
              }}
            >
              <Formik
                initialValues={{
                  name: selectModifyProduct?.name || "",
                  commodity: selectModifyProduct?.commodity || "",
                  sku: selectModifyProduct?.sku || "",
                  size: selectModifyProduct?.size || "",
                  price: selectModifyProduct?.price || "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Product Name is required"),
                  commodity: Yup.string().required(
                    "Product Commodity is required"
                  ),
                  price: Yup.string().required("Product Price is required"),
                })}
                onSubmit={handleOnSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.name && errors.name)}
                      sx={{ mt: 0 }}
                    >
                      <TextField
                        id="name"
                        type="text"
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        label="Product Name"
                      />

                      {touched.name && errors.name && (
                        <FormHelperText error id="name">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.commodity && errors.commodity)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Commodity
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values?.commodity}
                        error={Boolean(touched?.commodity && errors?.commodity)}
                        label="Commodity"
                        name="commodity"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                      >
                        {commodityData.map((i) => (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched?.commodity && errors?.commodity}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.sku && errors.sku)}
                      sx={{ mt: 0 }}
                    >
                      <TextField
                        id="sku"
                        type="text"
                        value={values.sku}
                        name="sku"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        label="Product SKU"
                      />

                      {touched.sku && errors.sku && (
                        <FormHelperText error id="sku">
                          {errors.sku}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.size && errors.size)}
                      sx={{ mt: 0 }}
                    >
                      <TextField
                        id="size"
                        type="text"
                        value={values.size}
                        name="size"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        label="Product Size"
                      />

                      {touched.size && errors.size && (
                        <FormHelperText error id="size">
                          {errors.size}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.price && errors.price)}
                      sx={{ mt: 0 }}
                    >
                      <TextField
                        id="price"
                        type="number"
                        value={values.price.toString()}
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        label="Product Price"
                      />

                      {touched.price && errors.price && (
                        <FormHelperText error id="price">
                          {errors.price}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {isLoading ? (
                          <CircularProgress color="secondary" size="25px" />
                        ) : (
                          "Modify Product"
                        )}
                      </Button>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </MainCard>
        </Modal>
      </Grid>
    </Box>
  );
}
