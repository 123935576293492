import { toast } from "react-toastify";
const TOAST_OPTIONS = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};
const defaultOtherErrorMessage =
  "Unknown Error: Please refer the console logs for more details on the error.";
export const showErrorToaster = (
  error,
  otherMessage = defaultOtherErrorMessage
) => {
  toast.dismiss();
  if (error && error?.message) {
    toast.error(error.message, TOAST_OPTIONS);
    return;
  }
  if (error && error.response) {
    if (error.response?.data) {
      if (error.response.data?.error) {
        toast.error(error.response.data.error, TOAST_OPTIONS);
      } else {
        toast.error(error.response.data, TOAST_OPTIONS);
      }
    } else {
      toast.error(error.response, TOAST_OPTIONS);
    }
  } else {
    toast.error(otherMessage, TOAST_OPTIONS);
  }
};
export const showSuccessToaster = (successMessage) => {
  console.log("successMessage: ", successMessage);
  //   toast.dismiss();
  toast.success(successMessage, TOAST_OPTIONS);
};
export const showInfoToaster = (infoMessage) =>
  toast.info(infoMessage, TOAST_OPTIONS);
export const showWarnToaster = (warnMessage) =>
  toast.warn(warnMessage, TOAST_OPTIONS);
