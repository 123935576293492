import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core";

const pages = [
  "User",
  "DataEntry",
  "Products",
  // "GetProduct",
  "ModifiedProducts",
  "TaxInfo",
];
const settings = ["Logout"];

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "#6495ed",
    },
  },
});

function NavBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedHeader, setSelectedHeader] = React.useState("");
  const navigate = useNavigate();
  const classes = useStyles();
  const userIsLoggedIn = Boolean(localStorage.getItem("accessToken"));
  const companyName = localStorage.getItem("company-name");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setSelectedHeader(e?.target?.textContent);
    setAnchorElNav(null);
    if (
      e?.target?.textContent === "User" ||
      e?.target?.textContent === "User "
    ) {
      navigate("/userlist");
    } else if (
      e?.target?.textContent === "Products" ||
      e?.target?.textContent === "Products "
    ) {
      navigate("/allproducts");
    } else if (
      e?.target?.textContent === "DataEntry" ||
      e?.target?.textContent === "DataEntry "
    ) {
      navigate("/addproduct");
    } else if (
      e?.target?.textContent === "TaxInfo" ||
      e?.target?.textContent === "TaxInfo "
    ) {
      navigate("/taxinfo");
    }
    //  else if (e?.target?.textContent === "GetProduct") {
    //   navigate("/getproduct");
    // }
    else if (
      e?.target?.textContent === "ModifiedProducts" ||
      e?.target?.textContent === "ModifiedProducts "
    ) {
      navigate("/updatedproduct");
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AppBar position="static">
      {userIsLoggedIn && (
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page} </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  className={classes.button}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontSize: 18,
                    textTransform: "none",
                    backgroundColor: selectedHeader === page ? "#6495ed" : "",
                  }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <Button
                  onClick={handleOpenUserMenu}
                  className={classes.button}
                  sx={{
                    p: 1,
                    backgroundColor: "cornflowerblue",
                    color: "white",
                    height: "30%",
                  }}
                >
                  {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                  {companyName}
                </Button>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      )}
    </AppBar>
  );
}
export default NavBar;
