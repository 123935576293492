import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  FormHelperText,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles, FormControl } from "@material-ui/core";

import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "../utils/Loader";
import DataUploadDialog from "../utils/DataUploadDialog";
import * as XLSX from "xlsx";
import CircularProgress from "@mui/material/CircularProgress";
import MainCard from "../utils/MainCard";
import axios from "axios";
import { showErrorToaster, showSuccessToaster } from "../utils";

const useStyles = makeStyles((theme) => ({
  inputStyle: {
    marginTop: 0,
  },
}));

export default function AddProduct() {
  const [customeropen, setCustomerOpen] = React.useState(false);
  const [selectedFileName, setSelectedFileName] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [columnsData, setColumnsData] = useState([]);
  const [file, setFile] = useState();
  const [excelFile, setExcelFile] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [excelFileError, setExcelFileError] = useState(null);
  const classes = useStyles();

  // submit
  const [excelData, setExcelData] = useState(null);

  const commodityData = [
    "Beer",
    "Drinks",
    "Food",
    "Liquor",
    "Candy",
    "Tobacco",
    "Wine",
  ];

  const handleCloseDialogCustomer = () => {
    setCustomerOpen(false);
  };

  const handleOnSubmit = async (values) => {
    try {
      values.price = values.price.toString();
      setIsLoading(true);
      const url =
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/storeProducts";
      const data = [values];
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
      };
      const addProducts = await axios.post(url, data, {
        headers: headers,
      });
      if (addProducts?.data?.statusCode === 200) {
        showSuccessToaster(addProducts?.data?.body);
        setIsLoading(false);
        setOpenModal(false);
      }
    } catch (error) {
      showErrorToaster("", "Something Went Wrong.Please try again.");
      setIsLoading(false);
      setOpenModal(false);
    }
  };

  const handleManuallySubmit = () => {
    setOpenModal(true);
  };

  // handle File
  const fileType = ["application/vnd.ms-excel"];
  const handleFile = (e) => {
    let selectedFile = e.target.files[0];
    setSelectedFileName(selectedFile?.name);
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      showErrorToaster("", "plz select your file");
    }
  };

  // submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    setSelectedFileName("");
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      const filHeader = Object.keys(data[0]);
      const fileHeaderObject = filHeader.map((val) => {
        return {
          csvColumnsName: val,
          defaultValue: "",
        };
      });
      setColumnsData(fileHeaderObject);
      setExcelData(data);
      setCustomerOpen(true);
      setFile(excelFile);
    } else {
      setExcelData(null);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: "95%", margin: "auto", backgroundColor: "aliceblue" }}>
      <Grid sx={{ display: "flex", justifyContent: "space-between" }}>
        <Grid sx={{ marginLeft: "10px" }}>
          <Typography sx={{ textAlign: "end", mr: 5 }}>
            Add Products Manually
          </Typography>
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 1 }}
            onClick={handleManuallySubmit}
          >
            Add Mannual Product
          </Button>
        </Grid>
        <Grid>
          <Typography sx={{ textAlign: "end", mr: 5 }}>
            Bulk Upload Data
          </Typography>
          <Grid
            sx={{
              display: "flex",
              paddingTop: "10px",
              marginRight: "10px",
              marginBottom: "10px",
              justifyContent: "end",
            }}
          >
            <Grid
              xs={6}
              md={8}
              sx={{
                textAlign: "end",
              }}
            >
              <Button variant="outlined" component="label" sx={{ mr: 2 }}>
                <input
                  type="file"
                  hidden
                  className="form-control"
                  onChange={handleFile}
                  required
                ></input>
                Choose File
              </Button>
              {selectedFileName && <Typography>{selectedFileName}</Typography>}
              {excelFileError && (
                <div className="text-danger" style={{ marginTop: 5 + "px" }}>
                  {excelFileError}
                </div>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                component="label"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {file && (
        <DataUploadDialog
          open={customeropen}
          handleCloseDialog={handleCloseDialogCustomer}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          columnsData={columnsData}
          excelData={excelData}
          setExcelData={setExcelData}
          file={file}
          setFile={setFile}
          setColumnsData={setColumnsData}
        />
      )}
      <Grid item xs={6} sm={6}>
        <Modal
          open={openModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ mt: 10 }}
        >
          {/* {modelBody} */}
          <MainCard
            sx={{
              width: 450,
              zIndex: 1,
              margin: "auto",
            }}
            title="Add Products"
            content={false}
          >
            <CardContent
              sx={{
                height: "100%",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <Formik
                initialValues={{
                  name: "",
                  code: "",
                  commodity: "",
                  size: "",
                  sku: "",
                  price: "",
                }}
                validationSchema={Yup.object().shape({
                  name: Yup.string().required("Product Name is required"),
                  code: Yup.string().required("Product Code is required"),
                  commodity: Yup.string().required(
                    "Product Commodity is required"
                  ),
                  price: Yup.string().required("Product Price is required"),
                })}
                onSubmit={handleOnSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.code && errors.code)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Code
                      </InputLabel>
                      <TextField
                        id="code"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.code}
                        name="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.code && errors.code && (
                        <FormHelperText error id="code">
                          {errors.code}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.name && errors.name)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Name
                      </InputLabel>
                      <TextField
                        id="name"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.name && errors.name && (
                        <FormHelperText error id="name">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.commodity && errors.commodity)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Commodity
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values?.commodity}
                        error={Boolean(touched?.commodity && errors?.commodity)}
                        label="Commodity"
                        name="commodity"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                      >
                        {commodityData.map((i) => (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched?.commodity && errors?.commodity}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.size && errors.size)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Size
                      </InputLabel>
                      <TextField
                        id="size"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.size}
                        name="size"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.size && errors.size && (
                        <FormHelperText error id="size">
                          {errors.size}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.sku && errors.sku)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product SKU
                      </InputLabel>
                      <TextField
                        id="sku"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.sku}
                        name="sku"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.sku && errors.sku && (
                        <FormHelperText error id="sku">
                          {errors.sku}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.price && errors.price)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Price
                      </InputLabel>
                      <TextField
                        id="price"
                        type="number"
                        classes={{ root: classes.inputStyle }}
                        value={values.price.toString()}
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.price && errors.price && (
                        <FormHelperText error id="price">
                          {errors.price}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {isLoading ? (
                          <CircularProgress color="secondary" size="25px" />
                        ) : (
                          "Add Product"
                        )}
                      </Button>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </MainCard>
        </Modal>
      </Grid>
    </Box>
  );
}
