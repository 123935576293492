import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Checkbox,
  Typography,
  Grid,
  Tooltip,
  Select,
  IconButton,
  TableSortLabel,
  TextField,
  MenuItem,
  FormHelperText,
  CardContent,
  InputLabel,
  Modal,
  Button,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import MuiTableCell from "@material-ui/core/TableCell";
import { Chip, FormControl, Input, makeStyles } from "@material-ui/core";
import axios from "axios";
import Loader from "../utils/Loader";
import { showErrorToaster, showSuccessToaster } from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import MainCard from "../utils/MainCard";

const useStyles = makeStyles((theme) => ({
  formControlRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    width: "75%",
    flexWrap: "wrap",
    flexDirection: "row",
    border: "2px solid lightgray",
    padding: 4,
    borderRadius: "4px",
    "&> div.container": {
      gap: "6px",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "& > div.container > span": {
      backgroundColor: "gray",
      padding: "1px 3px",
      borderRadius: "4px",
    },
  },
  inputStyle: {
    marginTop: 0,
  },
  LiquorStyle: {
    backgroundColor: "yellow",
  },
  BeerStyle: {
    backgroundColor: "#f28e1c",
  },
  DrinksStyle: {
    backgroundColor: "#C19730",
  },
  FoodStyle: {
    backgroundColor: "#33FF99",
  },
  CandyStyle: {
    backgroundColor: "#33CEFF ",
  },
  TobaccoStyle: {
    backgroundColor: "#B9BFC1",
  },
  WineStyle: {
    backgroundColor: "#77B2E9",
  },
  row: {
    backgroundColor: "transparent",
  },
}));
const columns = [
  {
    id: "checkBox",
    label: "Select",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "code",
    label: "Code",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "commodity",
    label: "Commodity",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "sku",
    label: "SKU",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },

  {
    id: "price",
    label: "Price",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "salestax",
    label: "Sales Tax",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "waLitterTax",
    label: "waLitterTax",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "total",
    label: "Total",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

let selectedProduct = [];

export default function UserList() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [selectModifyProduct, setSelectModifyProduct] = React.useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("price");
  const [values, setValues] = useState([]);
  const [currValue, setCurrValue] = useState("");
  const taxInfo = localStorage.getItem("taxInfo");
  const tax = JSON.parse(taxInfo);
  const classes = useStyles();

  const commodityData = [
    "Beer",
    "Drinks",
    "Food",
    "Liquor",
    "Candy",
    "Tobacco",
    "Wine",
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDelete = (item, index) => {
    let arr = [...values];
    arr.splice(index, 1);
    setValues(arr);
  };

  const handleGetProducts = async () => {
    if (values?.length > 0) {
      try {
        setIsLoading(true);
        const token = localStorage.getItem("accessToken");

        const headers = {
          Authorization: token,
          sales_tax: tax?.sales_tax || 0.102,
          liter_tax: tax?.liter_tax || 0.0037708,
          sprit_tax: tax?.sprit_tax || 0.205,
        };
        const getProducts = await axios.post(
          "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/getproducts",
          values,
          {
            headers: headers,
          }
        );
        if (getProducts?.data?.statusCode === 200) {
          setRows(getProducts?.data?.data);
          setIsLoading(false);
        }
      } catch (error) {
        showErrorToaster("", "Something Went Wrong.Please try again.");
        setIsLoading(false);
      }
    } else {
      getProductsList();
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getProductsList = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
        sales_tax: tax?.sales_tax || 0.102,
        liter_tax: tax?.liter_tax || 0.0037708,
        sprit_tax: tax?.sprit_tax || 0.205,
      };
      const allProducts = await axios.get(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/getallProducts",
        {
          headers: headers,
        }
      );
      setRows(allProducts?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showErrorToaster("", "Something Went Wrong.Please try again.");
    }
  };

  const handleChange = (e) => {
    setCurrValue(e.target.value);
  };

  const handleGetAllProducts = () => {
    getProductsList();
  };

  const handleSelectedRow = (e, row) => {
    if (e.target.checked) {
      setSelectedProducts([...selectedProducts, row]);
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== row));
    }
  };

  const openLink = (url) => {
    window.open(url, "_blank")?.focus();
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      setValues((oldState) => [...oldState, e.target.value]);
      setCurrValue("");
    }
  };

  const handleOnSubmit = async (values) => {
    try {
      setIsLoading(true);
      values.price = values?.price.toString();
      const url =
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/storeProducts";
      const data = [{ ...selectModifyProduct, ...values }];
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
      };
      const addProducts = await axios.post(url, data, {
        headers: headers,
      });
      if (addProducts?.data?.statusCode === 200) {
        showSuccessToaster("Product updated successfully");
        setIsLoading(false);
        setOpenModal(false);
        getProductsList();
      }
    } catch (error) {
      showErrorToaster("", "Something Went Wrong.Please try again.");
      setIsLoading(false);
      setOpenModal(false);
    }
  };

  const handleCreatePDF = async () => {
    if (selectedProducts?.length === 0) {
      showErrorToaster("", "Please select a product first");
    } else {
      setIsLoading(true);
      try {
        const token = localStorage.getItem("accessToken");

        const headers = {
          Authorization: token,
          sale: true,
          size: tax?.size || "two_x_one_5",
        };
        const createPdf = await axios.post(
          "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/createPdf",
          selectedProducts,
          {
            headers: headers,
          }
        );
        if (createPdf?.data?.url) {
          setIsLoading(false);
          selectedProduct = [];
          setSelectedProducts([]);
          openLink(createPdf?.data?.url);
        }
      } catch (error) {
        showErrorToaster("", "Something Went Wrong.Please try again.");
        selectedProduct = [];
        setSelectedProducts([]);
        setIsLoading(false);
      }
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleResetInputField = () => {
    setValues([]);
    setRows([]);
  };

  const handleEditProduct = (row) => {
    setOpenModal(true);
    setSelectModifyProduct(row);
  };

  // table sort
  function descendingComparator(a, b, orderBy) {
    if (
      orderBy === "price" ||
      orderBy === "salestax" ||
      orderBy === "waLitterTax" ||
      orderBy === "total"
    ) {
      if (Number(b[orderBy]) < Number(a[orderBy])) {
        return -1;
      }
      if (Number(b[orderBy]) > Number(a[orderBy])) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  }

  const getComparator = (order, orderBy) =>
    order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = () => {
    setSelectedProducts(rows);
  };
  const handleDeSelectAllClick = () => {
    setSelectedProducts([]);
  };

  // ==============================|| TABLE HEADER ||============================== //
  function EnhancedTableHead({ order, orderBy, onRequestSort, columns }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                sx={{ fontWeight: 600 }}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={headCell?.cellSize}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell
            sortDirection={false}
            align="center"
            sx={{ pr: 3, minWidth: 170 }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: "grey.900",
                fontWeight: "bold",
                fontSize: 14,
              }}
            >
              Action
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: "95%", margin: "auto", backgroundColor: "aliceblue" }}>
      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
      <Grid
        sx={{
          marginTop: "10px",
          marginBottom: "10px",
          textAlign: "end",
        }}
      >
        <Button
          variant="contained"
          sx={{ mr: 5 }}
          onClick={handleGetAllProducts}
        >
          All Products
        </Button>
        <Button
          variant="contained"
          sx={{ mr: 5 }}
          onClick={handleSelectAllClick}
        >
          Select All
        </Button>
        <Button
          variant="contained"
          sx={{ mr: 5 }}
          disabled={selectedProducts?.length === 0 ? true : false}
          onClick={handleDeSelectAllClick}
        >
          DeSelect All
        </Button>
        <Button
          variant="contained"
          onClick={handleCreatePDF}
          disabled={selectedProducts?.length === 0 ? true : false}
        >
          Get PDF Label
        </Button>
      </Grid>
      <div
        className="App"
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: "10px",
          paddingTop: "6px",
        }}
      >
        <FormControl classes={{ root: classes.formControlRoot }}>
          <div className={"container"}>
            {values.map((item, index) => (
              <Chip
                size="small"
                onDelete={() => handleDelete(item, index)}
                label={item}
              />
            ))}
          </div>
          <Input
            value={currValue}
            onChange={handleChange}
            onKeyDown={handleKeyUp}
          />
        </FormControl>

        <Button variant="contained" onClick={handleGetProducts}>
          {isLoading ? (
            <CircularProgress color="secondary" size="25px" />
          ) : (
            "Submit"
          )}
        </Button>
        <Button variant="contained" onClick={handleResetInputField}>
          Reset
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length}
            columns={columns}
          />
          <TableBody>
            {rows.length > 0 &&
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.code}>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Checkbox
                          checked={selectedProducts.some(
                            (a) => a.id === row.id
                          )}
                          onChange={(e) => handleSelectedRow(e, row)}
                        />
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.name}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.code}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Typography
                          classes={{
                            root:
                              row?.commodity === "Beer"
                                ? classes.BeerStyle
                                : row?.commodity === "Drinks"
                                ? classes.DrinksStyle
                                : row?.commodity === "Food"
                                ? classes.FoodStyle
                                : row?.commodity === "Liquor"
                                ? classes.LiquorStyle
                                : row?.commodity === "Candy"
                                ? classes.CandyStyle
                                : row?.commodity === "Tobacco"
                                ? classes.TobaccoStyle
                                : row?.commodity === "Wine"
                                ? classes.WineStyle
                                : classes.row,
                          }}
                        >
                          {row?.commodity}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.sku}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.size}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.price}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.salesTax === "NaN" ? "" : row?.salesTax}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.waLitterTax === "NaN" ? "" : row?.waLitterTax}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.total === "NaN" ? "" : row?.total}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        <Tooltip title="Delete">
                          <IconButton
                            size="large"
                            onClick={() => handleEditProduct(row)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Grid item xs={6} sm={6}>
        <Modal
          open={openModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ mt: 10 }}
        >
          {/* {modelBody} */}
          <MainCard
            sx={{
              width: 450,
              zIndex: 1,
              margin: "auto",
            }}
            title="Modify Product"
            content={false}
          >
            <CardContent
              sx={{
                height: "100%",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              <Formik
                initialValues={{
                  code: selectModifyProduct?.code || "",
                  name: selectModifyProduct?.name || "",
                  commodity: selectModifyProduct?.commodity || "",
                  size: selectModifyProduct?.size || "",
                  sku: selectModifyProduct?.sku || "",
                  price: selectModifyProduct?.price || "",
                }}
                validationSchema={Yup.object().shape({
                  code: Yup.string().required("Product Code is required"),
                  name: Yup.string().required("Product Name is required"),
                  commodity: Yup.string().required(
                    "Product Commodity is required"
                  ),
                  price: Yup.string().required("Product Price is required"),
                })}
                onSubmit={handleOnSubmit}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.code && errors.code)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Code
                      </InputLabel>
                      <TextField
                        id="code"
                        classes={{ root: classes.inputStyle }}
                        type="text"
                        value={values.code}
                        name="code"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                        // label="Product Code"
                      />

                      {touched.code && errors.code && (
                        <FormHelperText error id="code">
                          {errors.code}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.name && errors.name)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Name
                      </InputLabel>
                      <TextField
                        id="name"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.name && errors.name && (
                        <FormHelperText error id="name">
                          {errors.name}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl
                      fullWidth
                      margin="normal"
                      error={Boolean(touched.commodity && errors.commodity)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Commodity
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values?.commodity}
                        classes={{ root: classes.inputStyle }}
                        error={Boolean(touched?.commodity && errors?.commodity)}
                        label="Commodity"
                        name="commodity"
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleBlur(e)}
                      >
                        {commodityData.map((i) => (
                          <MenuItem value={i} key={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {touched?.commodity && errors?.commodity}
                      </FormHelperText>
                    </FormControl>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.size && errors.size)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Size
                      </InputLabel>
                      <TextField
                        id="size"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.size}
                        name="size"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.size && errors.size && (
                        <FormHelperText error id="size">
                          {errors.size}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Product SKU
                    </InputLabel>
                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.sku && errors.sku)}
                      sx={{ mt: 0 }}
                    >
                      <TextField
                        id="sku"
                        type="text"
                        classes={{ root: classes.inputStyle }}
                        value={values.sku}
                        name="sku"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.sku && errors.sku && (
                        <FormHelperText error id="sku">
                          {errors.sku}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <FormControl
                      fullWidth
                      variant="standard"
                      error={Boolean(touched.price && errors.price)}
                      sx={{ mt: 0 }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Product Price
                      </InputLabel>
                      <TextField
                        id="price"
                        type="number"
                        classes={{ root: classes.inputStyle }}
                        value={values.price.toString()}
                        name="price"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        margin="normal"
                        fullWidth
                      />

                      {touched.price && errors.price && (
                        <FormHelperText error id="price">
                          {errors.price}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="text-center"
                    >
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        {isLoading ? (
                          <CircularProgress color="secondary" size="25px" />
                        ) : (
                          "Modify Product"
                        )}
                      </Button>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          setOpenModal(false);
                        }}
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </CardContent>
          </MainCard>
        </Modal>
      </Grid>
    </Box>
  );
}
