import React, { useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  Container,
  Typography,
  Grid,
  Link,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import CircularProgress from "@mui/material/CircularProgress";
import { showErrorToaster, showSuccessToaster } from "../utils";

function Signup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (values) => {
    setIsLoading(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        user: "admin",
      };
      values.group = "store_admin";
      values.preferred_username = values.email;
      values.phone_number = `+1${values.phone_number.toString()}`;
      const createUser = await axios.post(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/createuser",
        values,
        {
          headers: headers,
        }
      );
      if (createUser?.data?.User?.Username) {
        showSuccessToaster("User Created Successfully.Please Login here");
        setIsLoading(false);
        navigate("/login");
      }
      console.log("createUser: ", createUser);
      if (createUser?.data?.errorMessage) {
        showErrorToaster("", createUser?.data?.errorMessage);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      showErrorToaster("", "Something Went Wrong.Please try again.");
    }
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Formik
            initialValues={{
              given_name: "",
              middle_name: "",
              family_name: "",
              email: "",
              phone_number: "",
              company: "",
              address: "",
            }}
            validationSchema={Yup.object().shape({
              given_name: Yup.string().required("First Name is required"),
              middle_name: Yup.string().required("Middle Name is required"),
              family_name: Yup.string().required("Last Name is required"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              phone_number: Yup.string().required("Phone Number is required"),
              company: Yup.string().required("Company Name is required"),
              address: Yup.string().required("Address is required"),
            })}
            onSubmit={handleOnSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.given_name && errors.given_name)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="given_name"
                    type="text"
                    value={values.given_name}
                    name="given_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="First Name"
                  />

                  {touched.given_name && errors.given_name && (
                    <FormHelperText error id="given_name">
                      {errors.given_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.middle_name && errors.middle_name)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="middle_name"
                    type="text"
                    value={values.middle_name}
                    name="middle_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Middle Name"
                  />
                  {touched.middle_name && errors.middle_name && (
                    <FormHelperText error id="middle_name">
                      {errors.middle_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.family_name && errors.family_name)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="family_name"
                    type="text"
                    value={values.family_name}
                    name="family_name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Last Name"
                  />

                  {touched.family_name && errors.family_name && (
                    <FormHelperText error id="family_name">
                      {errors.family_name}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="email"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Email"
                  />

                  {touched.email && errors.email && (
                    <FormHelperText error id="email">
                      {errors.email}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.address && errors.address)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="address"
                    type="text"
                    value={values.address}
                    name="address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Address"
                  />

                  {touched.address && errors.address && (
                    <FormHelperText error id="address">
                      {errors.address}
                    </FormHelperText>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.phone_number && errors.phone_number)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="phone_number"
                    type="number"
                    value={values.phone_number.toString()}
                    name="phone_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Phone Number"
                  />

                  {touched.phone_number && errors.phone_number && (
                    <FormHelperText error id="phone_number">
                      {errors.phone_number}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={Boolean(touched.company && errors.company)}
                  sx={{ mt: 0 }}
                >
                  <TextField
                    id="company"
                    type="text"
                    value={values.company}
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                    label="Company Name"
                  />

                  {touched.company && errors.company && (
                    <FormHelperText error id="company">
                      {errors.company}
                    </FormHelperText>
                  )}
                </FormControl>

                <Box sx={{ mt: 2 }} className="text-center">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" size="25px" />
                    ) : (
                      " Sign Up"
                    )}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <Grid item sx={{ textAlign: "center" }}>
          <Link href="/login" variant="body2">
            {"Already have an account? Sign in"}
          </Link>
        </Grid>
      </Container>
    </>
  );
}

export default Signup;
