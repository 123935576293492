import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Auth } from "aws-amplify";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import { showErrorToaster } from "../utils";
import { FormHelperText, FormControl } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";

function ForgotPassWord() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleOnSubmit = async (values) => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(values?.email);
      await Auth.currentSession();
      setIsLoading(false);
      navigate("/login");
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
      showErrorToaster("", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
          })}
          onSubmit={handleOnSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                variant="standard"
                error={Boolean(touched.newPassword && errors.newPassword)}
                sx={{ mt: 0 }}
              >
                <TextField
                  value={values.email}
                  name="email"
                  type="text"
                  id="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                  label="Email Address"
                />
                {touched.email && errors.email && (
                  <FormHelperText error id="email">
                    {errors.email}
                  </FormHelperText>
                )}
              </FormControl>

              <Button
                fullWidth
                variant="contained"
                type="submit"
                sx={{ mt: 3, mb: 2 }}
              >
                {isLoading ? (
                  <CircularProgress color="secondary" size="25px" />
                ) : (
                  " Submit"
                )}
              </Button>
              <Grid>
                <Link href="/login" variant="body2">
                  {"Already have an account? Sign in"}
                </Link>
              </Grid>
              <Grid container>
                <Grid item>
                  <Link href="/signup" variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );
}

export default ForgotPassWord;
