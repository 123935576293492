import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import DeleteIcon from "@mui/icons-material/Delete";
import MainCard from "../utils/MainCard";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Button,
  IconButton,
  Tooltip,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import MuiTableCell from "@material-ui/core/TableCell";
import CreateUser from "../components/CreateUser";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../utils/Loader";
import { showErrorToaster, showSuccessToaster } from "../utils";

const columns = [
  {
    id: "population",
    label: "First Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "population",
    label: "Middle Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "population",
    label: "Last Name",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "population",
    label: "Group",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Phone Number",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Email",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

export default function UserList() {
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = React.useState(false);
  const [deletedEmail, setDeletedEmail] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const userRole = localStorage.getItem("user-group");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAction = async (e) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
      };
      const data = {
        email: deletedEmail,
      };
      const deleteUser = await axios.post(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/deleteusers",
        data,
        {
          headers: headers,
        }
      );
      setDeleteUserOpen(false);
      showSuccessToaster(deleteUser?.data?.body);

      await getUserList();
    } catch (error) {
      setIsLoading(false);
      setDeleteUserOpen(false);

      showErrorToaster("", "Something Went Wrong.Please try again.");
    }
  };
  const getUserList = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      const headers = {
        Authorization: token,
      };
      const userList = await axios.get(
        "https://aftj2s4k60.execute-api.us-east-1.amazonaws.com/dev/getusers",
        {
          headers: headers,
        }
      );
      setRows(userList.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      showErrorToaster("", "Something Went Wrong.Please try again.");
    }
  };

  useEffect(() => {
    getUserList();
  }, []);

  const handleCreateUser = () => {
    setOpenModal(true);
  };
  const handleClose = () => {
    setDeleteUserOpen(false);
  };
  const handleDeleteUser = (email) => {
    setDeleteUserOpen(true);
    setDeletedEmail(email);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box sx={{ width: "95%", margin: "auto", backgroundColor: "aliceblue" }}>
      {userRole === "store_admin" && (
        <Grid
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            textAlign: "end",
            marginRight: "10px",
          }}
        >
          <Button variant="contained" onClick={handleCreateUser}>
            Add User
          </Button>
        </Grid>
      )}
      {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell
                sortDirection={false}
                align="center"
                sx={{ pr: 3, minWidth: 170 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "grey.900",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                >
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.code}>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.given_name}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.middle_name}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.family_name}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.group}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.phone_number}
                      </TableCell>
                      <TableCell align="center" sx={{ cursor: "pointer" }}>
                        {row?.email}
                      </TableCell>
                      <TableCell align={"center"}>
                        {userRole === "store_admin" && (
                          <Tooltip title="Delete">
                            <IconButton
                              size="large"
                              onClick={() => handleDeleteUser(row.email)}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* </Paper> */}

      <Grid item xs={6} sm={6}>
        <Modal
          open={openModal}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ mt: 10 }}
        >
          {/* {modelBody} */}
          <MainCard
            sx={{
              width: 450,
              zIndex: 1,
              margin: "auto",
            }}
            title="Create User"
            content={false}
          >
            <CreateUser setOpenModal={setOpenModal} getUserList={getUserList} />
          </MainCard>
        </Modal>
      </Grid>

      <Dialog
        open={deleteUserOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="agree" onClick={(e) => handleAction(e)}>
            Yes
          </Button>
          <Button name="disagree" onClick={(e) => handleClose(e)} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
