import React, { useState } from "react";
// import {
//   AmplifyAuthenticator,
//   AmplifySignOut,
//   AmplifySignIn,
// } from "@aws-amplify/ui-react";
import {
  Box,
  FormHelperText,
  Container,
  Typography,
  FormControl,
  Grid,
  Link,
  Button,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import { showErrorToaster, showSuccessToaster } from "../utils";
import { Formik } from "formik";
import * as Yup from "yup";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();

  const handleSubmitLogin = async (values) => {
    setIsLoading(true);
    localStorage.setItem("email", values?.email);
    Auth.signIn(values?.email, values?.password)
      .then((response) => {
        if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
          setIsNewUser(true);
          setUserData(response);
          setIsLoading(false);
        } else {
          localStorage.setItem(
            "accessToken",
            response?.signInUserSession?.idToken?.jwtToken
          );
          localStorage.setItem(
            "company-name",
            response?.attributes["custom:company"]
          );
          localStorage.setItem(
            "user-group",
            response?.attributes["custom:group"]
          );
          navigate("/userlist");
        }
      })
      .catch((e) => {
        showErrorToaster("", e?.message);
        setIsLoading(false);
      });
  };

  const handleOnSubmit = (values) => {
    setIsLoading(true);

    Auth.completeNewPassword(
      userData, // the Cognito User Object
      values?.newPassword
    )
      .then((user) => {
        setIsLoading(false);
        showSuccessToaster("New Password has been successfully updated");
        setIsNewUser(false);
      })
      .catch((e) => {
        console.log("e: ", e);
        showErrorToaster("", e?.message);
        setIsLoading(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {console.log("isNewUser: ", isNewUser)}
        {isNewUser ? (
          <>
            <Typography component="h1" variant="h5">
              Change Password
            </Typography>
            <Formik
              initialValues={{
                newPassword: "",
              }}
              validationSchema={Yup.object().shape({
                newPassword: Yup.string().required("New Password is required"),
              })}
              onSubmit={handleOnSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} key="bbb">
                  {console.log("values: ", values)}
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    sx={{ mt: 0 }}
                  >
                    <TextField
                      margin="normal"
                      value={values.newPassword}
                      fullWidth
                      name="newPassword"
                      label="Enter your new password"
                      type="password"
                      id="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.newPassword && errors.newPassword && (
                      <FormHelperText error id="newPassword">
                        {errors.newPassword}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    // onClick={handleSubmitNewPassword}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" size="25px" />
                    ) : (
                      " Submit"
                    )}
                  </Button>

                  <Grid container>
                    <Link href="/login" variant="body2">
                      {"Already have an account? Sign in"}
                    </Link>
                  </Grid>
                  <Grid container>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
                password: Yup.string().required("Password is required"),
              })}
              onSubmit={handleSubmitLogin}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <form noValidate onSubmit={handleSubmit} key="aaa">
                  <FormControl
                    id="signin"
                    fullWidth
                    variant="standard"
                    error={Boolean(touched.email && errors.email)}
                    sx={{ mt: 0 }}
                  >
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.email && errors.email && (
                      <FormHelperText error id="email">
                        {errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                  <FormControl
                    fullWidth
                    variant="standard"
                    error={Boolean(touched.password && errors.password)}
                    sx={{ mt: 0 }}
                  >
                    <TextField
                      margin="normal"
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.password && errors.password && (
                      <FormHelperText error id="password">
                        {errors.password}
                      </FormHelperText>
                    )}
                  </FormControl>

                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    // onClick={handleSubmitNewPassword}
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" size="25px" />
                    ) : (
                      " Sign In"
                    )}
                  </Button>

                  <Grid container>
                    <Grid item xs>
                      <Link href="forgotpassword" variant="body2">
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link href="/signup" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        )}
      </Box>
    </Container>
  );
}

export default Login;
